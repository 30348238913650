import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Platform, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    isLoggedin: boolean = false;

    constructor(private http: HttpClient, private alertController: AlertController, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(identifier: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/auth/local`, { identifier, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigateByUrl('app/home');
    }

   forgetPass(email){
    return this.http.post<any>(`${environment.apiUrl}/auth/forgot-password`, email).pipe(map(response => {
      },
      error=>{
          return this.showErrorAlert("O e-mail não está cadastrado em nosso sistema");
      }

      ))

   }

   resetPass(password:String, passwordConfirmation:String, code:String){
    return this.http.post<any>(`${environment.apiUrl}/auth/reset-password`, {code:code, password:password, passwordConfirmation:passwordConfirmation}).pipe(map(response => {
          
      }))

   }

  register(uName: String, fName: String, lName: String, email: String, password: String, cpf:String) {
    return this.http.post(`${environment.apiUrl}/auth/local/register`,
      {username: uName, firstname: fName, lastname: lName, email: email, password: password, cpf:cpf}
    )
  }

isLoggedIn() {

    if (JSON.parse(localStorage.getItem('currentUser')) == null) {
      this.isLoggedin = false;
      return this.isLoggedin;
    }
    else {
      return true;
    }
  }

  providerLogin(accessToken:String, provider:String){

    return this.http.get<any>(`${environment.apiUrl}/auth/${provider}/callback?access_token=`+ accessToken);
  }
 
  showErrorAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Erro :(',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Redefinição de senha',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  getUserMetaByToken(token:String){

    return this.http.get<any>(`${environment.apiUrl}/usermetas/token/` + token)

  }

  sendHubLead(email:string, firstname:string, lastname:string){

    return this.http.post(`${environment.hubUrl}`,{email:email, firstname:firstname, lastname:lastname})

  }

  updatePhoneLead(email:string, firstname:string, lastname:string, phone:number){

        return this.http.post(`${environment.hubUrl}`,{email:email, firstname:firstname, lastname:lastname, mobile:phone, origem:environment.origem})


  }

}