import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { FormsModule } from '@angular/forms' 
import { ReactiveFormsModule} from '@angular/forms' 

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StarRatingModule } from 'angular-star-rating';

import { HttpClientModule , HTTP_INTERCEPTORS} from '@angular/common/http';

import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';

import localept from '@angular/common/locales/pt';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

registerLocaleData(localept, 'pt');


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, StarRatingModule.forRoot(), HttpClientModule, ReactiveFormsModule,BrowserAnimationsModule,GoogleTagManagerModule.forRoot({
      id: 'GTM-MWMMCWD',
    }), NgxGoogleAnalyticsModule.forRoot('G-3NHRH6NSNP'), NgxGoogleAnalyticsRouterModule.forRoot()],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },{ provide: LOCALE_ID, useValue: 'pt' },{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, LocationAccuracy, GoogleAnalytics, { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },],
  bootstrap: [AppComponent],
})
export class AppModule {}
