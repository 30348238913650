import { Component, OnInit } from '@angular/core';
import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor(private ga: GoogleAnalytics, private router: Router,
    private gtmService: GoogleTagManagerService,) {
    
  this.ga.startTrackerWithId('G-3NHRH6NSNP')
   .then(() => {
     console.log('Google analytics is ready now');
      this.ga.trackView('test');
     // Tracker is ready
     // You can now track pages or set additional information such as AppVersion or UserId
   })
   .catch(e => console.log('Error starting GoogleAnalytics', e));
    
  }
  
  ngOnInit(){
    
    // push GTM data layer for every visited page
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
    
  }
  
  customEvent() {

    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'button-click',
      data: 'my-custom-event',
    };
    this.gtmService.pushTag(gtmTag);

    alert('this is a custom event');
  }
}
