import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: ':id/:slug',
    loadChildren: () => import('./detalhes-da-farmacia/detalhes-da-farmacia.module').then( m => m.DetalhesDaFarmaciaPageModule)
  },  
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registrar',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'connect/google',
    loadChildren: () => import('./connect/google/redirect.module').then( m => m.RedirectPageModule)
  },
  {
    path: 'connect/facebook',
    loadChildren: () => import('./connect/facebook/redirect.module').then( m => m.RedirectPageModule)
  },
  {
    path: 'completar-cadastro',
    loadChildren: () => import('./complete/complete.module').then( m => m.CompletePageModule)
  },
  {
    path: 'esqueci-minha-senha',
    loadChildren: () => import('./forgetpass/forgetpass.module').then( m => m.ForgetpassPageModule)
  },
  {
    path: 'localizacao/:id/:slug',
    loadChildren: () => import('./localizacao/localizacao.module').then( m => m.LocalizacaoPageModule)
  },
  {
    path: 'newpass',
    loadChildren: () => import('./newpass/newpass.module').then( m => m.NewpassPageModule)
  },
  {
    path: 'google',
    loadChildren: () => import('./providers/google/google.module').then( m => m.GooglePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
